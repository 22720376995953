import React from 'react';
import clsx from 'clsx';

import { Column } from '../../atoms/grid';
import { Text, Link } from '../..';
import { ColumnUnit } from '../../types';
import styles from './footer-column.module.css';

export interface FooterColumnType {
  offset?: ColumnUnit;
  title: string;
  links: {
    copy: string;
    destination: string;
  }[];
}

export const FooterColumn: React.FC<FooterColumnType> = ({
  title,
  links,
  offset = 0
}) => {
  return (
    <Column
      className={clsx('flex', 'flex-col', styles.columnSection)}
      offset={offset}
    >
      <Text color="white" variant="p2b" className={styles.columnTitle}>
        {title}
      </Text>
      {links.map(l => (
        <Link
          color="white"
          variant="p2a"
          key={l.copy}
          destination={
            l.destination === '/contact'
              ? '/contact/free-consultation/'
              : l.destination
          }
          className={styles.columnLink}
        >
          {l.copy}
        </Link>
      ))}
    </Column>
  );
};
