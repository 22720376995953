import React, { FC } from 'react';
import clsx from 'clsx';

import mainStyles from '../navigation.module.css';
import styles from './nav-container.module.css';
import { useScrollPosition } from '../../../../hooks/use-scroll-position';

interface NavContainerProps {
  alwaysShow?: boolean;
  children?: any;
}

export const NavContainer: FC<NavContainerProps> = ({
  children,
  alwaysShow
}) => {
  const scrollPosition = useScrollPosition();
  const scrolled = scrollPosition.y > 15 && 'Scrolled';
  return (
    <header
      className={clsx(
        styles.header,
        scrolled && mainStyles[`header${scrolled}`],
        alwaysShow && styles.alwaysShow
      )}
      style={{ zIndex: 11 }}
    >
      {children}
    </header>
  );
};
