import React from 'react';

interface ArrowButtonProps {
  onClick: () => void;
  direction?: 'left' | 'right';
}

const ArrowButton = ({ onClick, direction = 'left' }: ArrowButtonProps) => {
  const leftArrowSvg = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
    >
      <path
        d="M6.99574 14.9988L15.1005 6.99964M6.99574 14.9988L15.1005 22.998M6.99574 14.9988L22.9941 14.9988"
        stroke="#4F7F71"
        strokeWidth="1.44"
      />
    </svg>
  );

  const rightArrowSvg = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
    >
      <path
        d="M22.9945 14.9987L14.8898 22.9979M22.9945 14.9987L14.8898 6.99951M22.9945 14.9987H6.99609"
        stroke="#4F7F71"
        strokeWidth="1.44"
      />
    </svg>
  );

  return (
    <button
      onClick={onClick}
      className="flex h-10 w-10 items-center justify-center rounded-full border border-gray-300 bg-white hover:bg-gray-100 focus:outline-none"
    >
      {direction === 'left' ? leftArrowSvg : rightArrowSvg}
    </button>
  );
};

export { ArrowButton };
