import React from 'react';
import { Hero } from '../../parsley-web/marketing/Hero';
import { Layout, Module, Structured, Text } from '../../redesign';
import { Conditions, StructuredData } from '../../data/new/homepage';
import { HalfAndHalfNumberedListModule } from '../../redesign/page-builder';
import { ProviderGridModule } from '../../parsley-web/ui/ProviderGrid';
import { QuoteCarousel } from '../../parsley-web/marketing/QuoteCarousel';
import ForbesIcon from '../../assets/forbes.svg';
import FrontiersIcon from '../../assets/frontiers.svg';
import MedCityIcon from '../../assets/med_city.svg';
import NewYorkTimesIcon from '../../assets/new-york-times.svg';
import WallStreetJournalIcon from '../../assets/wsj.svg';
import WomensHealthIcon from '../../assets/womenshealth.svg';
import { Pricing } from '../../parsley-web/marketing/Pricing';
import MakingItAccessible from '../../parsley-web/marketing/MakingItAccessible';

const NewHomepage = () => {
  return (
    <Layout>
      <Hero
        heading="Find the root cause of your health symptoms"
        subheading="Advanced diagnostic lab testing, a personalized treatment plan, and best-in-class clinicians from the leader in functional medicine."
        image={{
          src:
            'https://cdn.sanity.io/images/mur2ep80/production/87643580cfaeaa6fcf7968326d5251b0903a17b6-722x644.svg?fm=webp&w=1200&fm=webp',
          alt: 'Parsley Health'
        }}
        mobileImage={{
          src:
            'https://cdn.sanity.io/images/mur2ep80/production/c3c585a5fe12c7f775bc7f57bb2c7eaa71ce215f-750x462.webp?w=1024&fm=webp',
          alt: 'Parsley Health'
        }}
        primaryAction={{
          value: 'Schedule A Call',
          size: 'large',
          destination: '/contact/free-consultation'
        }}
      />
      <Module animate>
        <Structured {...StructuredData} twoColumn />
      </Module>
      <HalfAndHalfNumberedListModule
        image={{
          alt: 'doctors consulting computer',
          shape: 'rectangle',
          blob: true,
          // eslint-disable-next-line
          // @ts-ignore
          asset: {
            id: 'image-10e2d5da4c22cdc22d4774ab22b57eb0bafb78e3-1024x682-jpg',
            url:
              'https://cdn.sanity.io/images/mur2ep80/production/10e2d5da4c22cdc22d4774ab22b57eb0bafb78e3-8192x5460.webp?w=1024&fit=max&auto=format'
          }
        }}
        textLockup={{
          eyebrow: {
            text: 'HOW IT WORKS',
            variantStyle: 'p4a',
            isEnabled: true
          },
          heading: {
            text: "It's time to own your health",
            variantStyle: 'h9a',
            isEnabled: true
          },
          description: {
            text:
              "At Parsley, we practice medicine the way it's meant to be—treat the root cause first, so you get healthy and stay healthy.",
            variantStyle: 'p4b',
            isEnabled: true
          },
          primaryCta: {
            color: 'darkGreen',
            destination: '/contact/free-consultation',
            openInNewTab: null,
            size: 'large',
            text: 'Schedule A Call',
            trackingName: null,
            variant: 'outlined'
          }
        }}
        numberedList={[
          'Complete Parsley´s advanced Functional Lab Panel with 60+ hormone, metabolic, and nutrient biomarkers covering what doctors typically miss.',
          'Visit our functional medicine-trained clinicians for the full hour you deserve (no rushed appointments!) through video chat online or in person offline at our NYC and LA clinics.',
          'Get a personalized treatment plan that goes beyond medication to include nutrition, fitness, stress management, advanced testing (over 1,000 biomarkers), and supplements.',
          'Work with a personal health coach to implement your plan, make progress, and reach your goals.'
        ]}
      />

      <MakingItAccessible />

      <ProviderGridModule heading="Meet our expert clinicians and health coaches, all uniquely trained in our proven root-cause medicine method" />

      <div className="mt-16 md:my-24">
        <div className="text-center">
          <Text
            variant="p4a"
            className="text-ph-sage text-center text-[11px] font-medium leading-[11px] tracking-[0.88px] uppercase"
          >
            Conditions we cover
          </Text>
          <Text className="mb-4" color="dark-green" variant="h9a">
            Healing today's top conditions and symptoms.
          </Text>
        </div>

        <div className="grid grid-cols-2 gap-12 py-8 md:grid-cols-2 md:py-16 lg:grid-cols-4">
          {Conditions.map(({ title, description, icon }, index) => (
            <div className="flex flex-col items-center p-6 text-center">
              <div className="mask mask-blob-4-5 relative flex aspect-[4/5] h-[353px] w-[284px] items-center justify-center bg-ph-sage">
                {icon}
              </div>
              <Text className="mt-6" color="dark-green" variant="h7a">
                {title}
              </Text>
              <Text className="mt-6" variant="p2a">
                {description}
              </Text>
            </div>
          ))}
        </div>
      </div>

      <QuoteCarousel
        quotes={[
          {
            quote:
              'I’ve tried every method, doctor, diet, etc. Nothing has compared to the holistic treatment provided by the entire team at Parsley. Just incredible collective expertise that has made a massive difference in my healing after 10+ years of struggling.',
            quoteSource: 'Sam P.'
          },
          {
            quote:
              'I have tracked my symptoms for close to 13 years with no answers. Within 6 months of being a member of Parsley Health, my doctor figured out my root cause.',
            quoteSource: 'Terri P.'
          },
          {
            quote:
              'Total Cholesterol before was 256, now 182. Weight before was 208, now 170!! Parsley’s focus is on the root causes and the why’s and generally resolve issues without the need for prescription medications which usually treat only and may contain many adverse side effects. Parsley is on medicine 2.0.',
            quoteSource: 'Edward B.'
          },
          {
            quote:
              'My Parsley Nurse Practitioner finally helped me get relief from chronic issues with bloating and intermittent GI pain that was excruciating when it hit. She systematically helped me go through the diet changes and labs that led us to the most likely root cause to treat and I have been free from GI upset ever since.',
            quoteSource: 'Ekua W.'
          }
        ]}
        eyebrow={'From our customers'}
      />

      <Pricing />

      <div className="my-8">&nbsp;</div>
      <QuoteCarousel
        autoplay={false}
        quotes={[
          {
            quote:
              'Coaches take the time to get to know patients fully, find their internal motivation and set them up for success that’s personalized. Health happens in the 99.9 percent of your life when you’re not in the doctor’s office.',
            quoteSource: <NewYorkTimesIcon />
          },
          {
            quote:
              'Dr. Berzin launched Parsley Health, a proactive, preventative, and holistic wellness provider that integrates the latest in medical testing, technology, and research.',
            quoteSource: <ForbesIcon />
          },
          {
            quote:
              'Ultimately, the Parsley Health patients represented in this study felt cared for, heard by their providers, and felt much better after a year of receiving care.',
            quoteSource: <FrontiersIcon />
          },
          {
            quote:
              'Wellness is trendy in the same way that fashion is, but this column is going to be all about realistic, sustainable goals. I’ll be working with doctors at Parsley Health',
            quoteSource: <WomensHealthIcon />
          },
          {
            quote:
              'The national holistic medical practice Parsley offers all manner of research-backed motivations, as well as the kind of tough love that requires no explanation.',
            quoteSource: <WallStreetJournalIcon />
          },
          {
            quote:
              'But here’s the silver lining: some trailblazers are lighting the path forward. Parsley Health, providing root-cause resolution care, meaningfully invests in training its clinicians — every Parsley doctor completes their Parsley Fellowship in functional medicine and nutrition.',
            quoteSource: <MedCityIcon />
          }
        ]}
      />
    </Layout>
  );
};
export default NewHomepage;
