import * as React from 'react';

import ClinicianIcon from '../../../assets/clinician.svg';
import SeedIcon from '../../../assets/seed.svg';
import CareIcon from '../../../assets/care.svg';
import { Module, Structured, Text } from '../../../redesign';
import { StructuredProps } from '../../../redesign/types';

const StructuredData: StructuredProps = {
  alignment: 'center',
  items: [
    {
      illustration: <ClinicianIcon className="" />,
      heading: 'Medical Providers',
      description:
        'Each medical team is led by a doctor, nurse practitioner, or physician assistant with advancement training in root-cause medicine plus primary care specialties like family medicine and internal medicine.'
    },
    {
      illustration: <SeedIcon className="" />,
      heading: 'Health Coaches',
      description:
        "Get a dedicated health coach who's an expert in root cause medicine, behavioral change, and nutrition. Plus, get access to free health coaching courses with our Parsley Wellness Academy."
    },
    {
      illustration: <CareIcon className="" />,
      heading: 'Nurses',
      description:
        'Our nurses make sure your healthcare experience is seamless by helping with scheduling, coordinating labs, insurance questions, specialist referrals, and more.'
    }
  ]
};

export const YourTeamSection = () => {
  return (
    <Module animate className="text-center">
      <div className="flex flex-col gap-4">
        <Text variant="p4a" className="">
          YOUR TEAM
        </Text>
        <h2 className="text-teodor w-full text-ph-h1 px-6">
          Feel seen, feel better
        </h2>
        <Text
          variant="p4b"
          className="text-ph-p1 text-euclid mx-auto max-w-2xl"
        >
          Your Parsley team includes medical providers, health coaches, and
          nurses, all working in concert to help you live a healthier life. You
          can meet with them via secure video from the comfort of home—or in
          person at our NYC and LA medical centers.
        </Text>
      </div>
      <Structured {...StructuredData} />
    </Module>
  );
};
