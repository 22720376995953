import React from 'react';
import { BulletList, Image, Text } from '../../redesign';
import { ImageType } from '../../redesign/atoms/image/types';
import { baseSanityNode } from '../../redesign/content/base-sanity-node';
import { Collapsible } from '../../redesign/organisms/collapsible/collapsible';

export default function MakingItAccessible({
  title,
  subheading = 'We currently accept insurance plans in New York and California.'
}: {
  title?: string;
  subheading?: string;
}) {
  const [expandedItem, setExpandedItem] = React.useState<string | null>('1');

  return (
    <div className="flex flex-col lg:flex-row gap-14 md:gap-7 lg:items-start items-start justify-center px-4 md:px-[4.166%] py-24">
      <div className="w-full lg:w-1/2 py-16 min-[712px]:max-[877px]">
        <Text className="text-lockup-headline" color="dark-green" variant="h9a">
          {title || 'Making root-cause medicine more accessible'}
        </Text>
        {subheading && (
          <Text
            className="text-lockup-description"
            color="dark-green"
            variant="p4b"
          >
            {subheading}
          </Text>
        )}
        <Collapsible
          dataArray={[
            {
              ...baseSanityNode,
              id: '1',
              _type: 'customText',
              heading: 'California',
              _rawBody: {
                customText: (
                  <BulletList
                    className="pt-0 pb-8"
                    items={[
                      'Aetna',
                      'Blue Shield of California',
                      'UnitedHealthcare California'
                    ]}
                  />
                )
              }
            },
            {
              ...baseSanityNode,
              id: '2',
              _type: 'customText',
              heading: 'New York',
              _rawBody: {
                customText: (
                  <BulletList
                    className="pt-0 pb-8"
                    items={[
                      'Aetna',
                      'Centivo',
                      'CIGNA',
                      'HIP Health Insurance Plan of Greater New York',
                      'NY BCBS - Empire',
                      'Oscar Insurance',
                      'UMR - Wausau',
                      'UnitedHealthcare - Choice Plus',
                      'UnitedHealthcare - Freedom',
                      'UnitedHealthcare - Liberty',
                      'UnitedHealthcare - Options PPO',
                      'UnitedHealthcare - Oxford',
                      'UnitedHealthcare - Select',
                      'UnitedHealthcare - Select Plus'
                    ]}
                  />
                )
              }
            }
          ]}
          expandedItems={[expandedItem]}
          toggleItem={(id: string) =>
            setExpandedItem(expandedItem === id ? null : id)
          }
        />
      </div>
      <div className="flex lg:w-[50%] w-full items-center justify-center">
        <Image
          type={ImageType.SQUARE}
          blob
          sizes={[150, 300]}
          format="webp"
          className="lg:max-w-[640px] w-full"
          asset={{
            ...baseSanityNode,
            url:
              'https://cdn.sanity.io/images/mur2ep80/production/5b268d027fcc9843365d1436fbd020119080095e-1007x802.webp'
          }}
          alt={'Parsley Health'}
        />
      </div>
    </div>
  );
}
