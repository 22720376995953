import React from 'react';
import { useMediaQuery } from '../utils/hooks/use-media-query';
import { Carousel, CarouselContent, CarouselItem } from './Carousel';
import { QuoteCarouselNavBar } from './QuoteCarouselNavBar';
import Autoplay from 'embla-carousel-autoplay';
import { useState } from 'react';
import CarouselLine from '../../assets/carousel-line.svg';
import { ArrowButton } from '../ui/ArrowButton';
import { Text } from '../../redesign';

interface Quote {
  quote: string;
  quoteSource: string | JSX.Element;
}

interface QuoteCarouselProps {
  quotes: Quote[];
  eyebrow?: string;
  autoplay?: boolean;
}

export function QuoteCarousel({
  quotes,
  eyebrow,
  autoplay = true
}: QuoteCarouselProps) {
  const isCustomerQuote = typeof eyebrow === 'string';
  const isMobile = useMediaQuery('768px');
  const navColor = isCustomerQuote ? 'bg-gray-300' : 'bg-ph-clover';
  const [activeIndex, setActiveIndex] = useState(0);

  // Function to go to the next quote
  const goToNextSlide = () => {
    setActiveIndex(prevIndex => (prevIndex + 1) % quotes.length);
  };

  // Function to go to the previous quote
  const goToPrevSlide = () => {
    setActiveIndex(
      prevIndex => (prevIndex - 1 + quotes.length) % quotes.length
    );
  };

  // Function to handle manual transition
  const handleTransition = (index: number) => {
    setActiveIndex(index);
  };
  const plugins = autoplay
    ? [Autoplay({ delay: 4000, stopOnFocusIn: false })]
    : [];
  return (
    <Carousel
      className="relative lg:px-[208px] md:px-16"
      opts={{ align: 'start', loop: true }}
      plugins={plugins}
      setApi={api => {
        if (api) {
          api.on('scroll', data => {
            setActiveIndex(data.selectedScrollSnap());
          });
          api.scrollTo(activeIndex);
        }
      }}
    >
      <CarouselContent>
        {quotes.map((quoteObj, index) => (
          <CarouselItem key={index}>
            <div className="flex h-full flex-col justify-end px-6 md:px-[208px]">
              {eyebrow && (
                <Text
                  variant="p4a"
                  className="mb-6 text-ph-sage text-center text-[11px] font-medium leading-[11px] tracking-[0.88px] uppercase"
                >
                  {eyebrow}
                </Text>
              )}
              <Text variant="h6a" className="mb-6 text-center md:mb-12">
                “{quoteObj.quote}”
              </Text>
              <div className="flex flex-row justify-between">
                {!eyebrow && isMobile && (
                  <ArrowButton onClick={goToPrevSlide} direction="left" />
                )}
                {(eyebrow || isMobile) && (
                  <span className="text-ph-h5 flex flex-grow flex-col justify-center text-center">
                    {quoteObj.quoteSource}
                  </span>
                )}
                {!eyebrow && isMobile && (
                  <ArrowButton onClick={goToNextSlide} direction="right" />
                )}
              </div>
              {eyebrow && (
                <div className="mt-4 flex justify-center">
                  <CarouselLine />
                </div>
              )}
            </div>
          </CarouselItem>
        ))}
      </CarouselContent>
      <QuoteCarouselNavBar
        eyebrow={eyebrow}
        quotes={quotes}
        activeIndex={activeIndex}
        handleTransition={handleTransition}
        isCustomerQuote={isCustomerQuote}
        navColor={navColor}
      />
    </Carousel>
  );
}
