import React from 'react';
import { Button } from '../../redesign';
import { ButtonProps } from '../../redesign/atoms/button/button';

interface ActionProps {
  value: string;
  variant?: ButtonProps['variant'];
  size?: ButtonProps['size'];
  onClick?: () => void;
  destination?: string;
}

interface HeroProps {
  heading: string;
  subheading?: string;
  image?: React.ImgHTMLAttributes<HTMLImageElement>;
  mobileImage?: React.ImgHTMLAttributes<HTMLImageElement>;
  primaryAction?: ActionProps;
  secondaryAction?: ActionProps;
}

export const Hero = ({
  heading,
  subheading,
  image,
  primaryAction,
  secondaryAction,
  mobileImage
}: HeroProps) => {
  return (
    <section className="grid md:grid-cols-2">
      <div className="flex flex-col md gap-8 px-6 pb-12 pt-8 md:py-24 md:ml-16 md:px-0 __pw_hero">
        <div className="flex flex-col gap-4 text-start text-center">
          <h1 className="text-ph-forest ph-font-euclid-medium m-0 text-[40px] font-medium leading-snug tracking-tight md:text-[64px] text-lockup-headline">
            {heading}
          </h1>
          {subheading && (
            <h2 className="text-ph-forest ph-font-euclid-regular m-0 font-normal text-[20px] leading-loose tracking-tight text-lockup-description mb-6">
              {subheading}
            </h2>
          )}
        </div>
        {(primaryAction || secondaryAction) && (
          <div className="flex gap-4">
            {primaryAction && (
              <Button
                variant="filled"
                {...primaryAction}
                trackingLabel={primaryAction?.value}
              >
                {primaryAction?.value}
              </Button>
            )}
            {secondaryAction && (
              <Button
                trackingLabel={secondaryAction?.value}
                {...secondaryAction}
              >
                {secondaryAction?.value}
              </Button>
            )}
          </div>
        )}
      </div>
      <img className="hidden md:block" {...image} alt={image?.alt} />
      <img className="md:hidden" {...mobileImage} alt={mobileImage?.alt} />
    </section>
  );
};
