import React, { useEffect, useMemo, ReactNode, useRef } from 'react';
import clsx from 'clsx';
import { breakpoint } from '../../types/index';
import { Row, Column, Image, TextLockup } from './../../';
import { motion, useMotionValue, useTransform } from 'framer-motion';
import { useWindowSize } from '@parsleyhealth/cilantro-ui';
import useDOMRect from '../../../hooks/use-dom-rect';
import { useScrollPosition } from '../../../hooks/use-scroll-position';
import { ImageType } from '../../atoms/image/types';
import {
  GridOrientation,
  TextAlignment,
  ImageProps,
  CTAProps
} from '../../types';
import { useHeroGridConfig } from '../../../hooks/use-hero-grid-config';

import './hero.scss';
import { P3 } from '@ph-bit/design.ui.typography.p3';
import { H1 } from '@ph-bit/design.ui.typography.h1';
import { P2 } from '@ph-bit/design.ui.typography.p2';
import {
  Pill,
  PillColorTheme,
  PillVariant
} from '@ph-bit/design.ui.buttons.pill';
import { navigate } from 'gatsby';
import { useNewProgram } from '@parsleyhealth/cilantro-ui';

export type HeroVariant = 'home' | 'condition' | 'fullImage';
export interface HeroProps {
  asCTA?: (cta: CTAProps) => React.ElementType;
  cta?: CTAProps;
  description?: ReactNode;
  eyebrow?: string;
  headline: string;
  hideHeroImageOnMobile?: boolean;
  desktopImage: ImageProps;
  mobileImage?: ImageProps;
  isInsights?: boolean;
  orientation?: GridOrientation;
  keyline?: boolean;
  secondaryCta?: CTAProps;
  textAlign?: TextAlignment;
  variant?: HeroVariant;
  animate?: boolean;
}

export const Hero = ({
  asCTA,
  cta,
  description,
  eyebrow,
  headline,
  hideHeroImageOnMobile,
  desktopImage,
  mobileImage,
  orientation,
  isInsights = false,
  keyline,
  secondaryCta,
  textAlign = 'center',
  variant = 'condition',
  animate = false
}: HeroProps) => {
  const windowSize = useWindowSize();
  const { row, contentCol, imageCol } = useHeroGridConfig(variant);
  const isCorrectWidth = windowSize.width !== 0;
  const isMobile = windowSize.width <= breakpoint;
  const image = isMobile && mobileImage ? mobileImage : desktopImage;
  const imageSizes = [100, 50];
  const columnRef = useRef(null);
  const imageRef = useRef(null);
  const columnRect = useDOMRect(columnRef.current);
  const imageRect = useDOMRect(imageRef.current);
  const position = useScrollPosition();
  const scrollY = useMotionValue(0);
  useEffect(() => {
    scrollY.set(position.y + 120);
  }, [position, scrollY]);
  const fixed = useMemo(() => position.y + 80 < columnRect.bottom, [
    position,
    columnRect
  ]);
  const opacity = useTransform(
    scrollY,
    [imageRect.top - 30, imageRect.bottom - 30],
    [1, 0]
  );
  const displayAnimation = windowSize.width >= 1280 && animate;

  useEffect(() => {
    displayAnimation &&
      setTimeout(() => {
        window.dispatchEvent(new Event('resize'));
      }, 1000);
  }, [displayAnimation]);

  const { isAllowed } = useNewProgram(false, '/');

  const onClick = (path: string, cb?: () => void) => {
    if (Boolean(cb)) {
      cb();
    }
    navigate(path);
  };

  const multipleButtons = Boolean(cta && secondaryCta);

  return (
    <Row
      gridLength={row.gridLength}
      reversed={orientation}
      className={clsx({ 'hero-container': !hideHeroImageOnMobile })}
    >
      <Column {...contentCol} className="flex">
        <div
          className={clsx(
            'flex',
            'md:flex-center',
            'h-auto md:h-full',
            'hero-content flex-col',
            { 'hero-content-keyline': keyline }
          )}
        >
          <div className="flex-1 hidden-mobile" />
          <TextLockup
            alignment={isMobile ? 'center' : textAlign}
            className="hero-lockup"
          >
            <>
              {eyebrow && (
                <P3
                  variant="b"
                  className="text-lockup-eyebrow self-center-mobile mb-6"
                >
                  {eyebrow}
                </P3>
              )}
              <H1 className="text-lockup-headline mb-6">{headline}</H1>
              {description && (
                <P2 className="text-lockup-description mb-6">{description}</P2>
              )}
              {asCTA && asCTA(cta)}
              {!asCTA && cta && (
                <div className="inline-flex-center text-lockup-buttons-container">
                  <div>
                    <Pill
                      className={multipleButtons ? '' : 'hero-primary-button'}
                      variant={
                        multipleButtons ? PillVariant.secondary : undefined
                      }
                      onClick={() =>
                        onClick(
                          !isAllowed
                            ? '/pricing-and-insurance'
                            : cta.destination,
                          cta.onClick
                        )
                      }
                      role="button"
                    >
                      {cta.label}
                    </Pill>
                  </div>
                  {secondaryCta && (
                    <div>
                      <Pill
                        className="flex-center hero-primary-button"
                        colorTheme={PillColorTheme.sage}
                        role="button"
                        onClick={() =>
                          onClick(
                            secondaryCta.destination,
                            secondaryCta.onClick
                          )
                        }
                      >
                        {secondaryCta.label}
                      </Pill>
                    </div>
                  )}
                </div>
              )}
            </>
          </TextLockup>
          <div
            className={clsx('flex-1 w-full overflow-hidden relative', {
              'hero-keyline': keyline
            })}
          />
        </div>
      </Column>

      {(!hideHeroImageOnMobile || (hideHeroImageOnMobile && !isMobile)) && (
        <Column
          {...imageCol}
          className={clsx(
            'flex items-center',
            hideHeroImageOnMobile && 'mt-hero',
            isInsights
              ? 'hero-image-column-insights-mobile'
              : 'hero-image-column'
          )}
        >
          {displayAnimation ? (
            <div ref={columnRef} style={{ height: imageRect.height }}>
              <motion.div
                className={clsx({
                  'hero-image-fixed': fixed
                })}
                style={{
                  opacity
                }}
                ref={imageRef}
              >
                <Image
                  {...image}
                  type={image?.type ?? ImageType.SQUARE}
                  style={{ width: columnRect.width }}
                  sizes={imageSizes}
                />
              </motion.div>
            </div>
          ) : isCorrectWidth ? (
            <Image
              {...image}
              type={image?.type ?? ImageType.LARGE}
              loading="eager"
              decoding="sync"
              sizes={imageSizes}
            />
          ) : null}
        </Column>
      )}
    </Row>
  );
};

export default Hero;
