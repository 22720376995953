import React from 'react';
import { Layout } from '../redesign';
import { ProviderGridModule } from '../parsley-web/ui/ProviderGrid';

import { YourTeamSection } from '../parsley-web/how-we-work/components/YourTeamSection';
import { HowWeWorkHero } from '../parsley-web/how-we-work/Hero';
import PerksSection from '../parsley-web/how-we-work/components/PerksSection';
import Timeline from '../parsley-web/how-we-work/components/Timeline';

export default function HowWeWork() {
  return (
    <Layout>
      <HowWeWorkHero />
      <Timeline />
      <div className="bg-ph-ivory">
        <YourTeamSection />
        <ProviderGridModule
          heading="Meet our expert clinicians and health coaches, all uniquely trained in our proven root-cause medicine method"
          className="pt-0 md:py-24"
        />
      </div>
      <div className="bg-ph-forest">
        <PerksSection />
      </div>
    </Layout>
  );
}
