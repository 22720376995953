import React from 'react';
import { useState, useRef, useEffect } from 'react';
import { cn } from '../../utils/class-name';

import { ArrowIcon } from '../../../redesign/molecules/icons';

import { Description } from './timelineData';
import { TIMELINE_DATA } from './timelineData';
import { BulletList, Button, Link, Text } from '../../../redesign';
import { baseSanityNode } from '../../../redesign/content/base-sanity-node';
import { Collapsible } from '../../../redesign/organisms/collapsible/collapsible';
// import ParsleyAccordion from './ParsleyAccordion';

interface TimelineItemProps {
  title: string;
  description: Description;
  extraContent?: { title: string; description: Description }[];
  imagePosition?: 'left' | 'right';
  imageUrl?: string;
  isVisible: boolean;
  number: string;
}

// Extracted shared components
const TimelineTitle = ({ children }: { children: React.ReactNode }) => (
  <h3 className="text-[28px] font-medium leading-[112%] tracking-[-0.56px] text-ph-forest md:text-[32px] md:tracking-[-0.64px]">
    {children}
  </h3>
);

const TimelineDescription = ({ children }: { children: React.ReactNode }) => (
  <p className="whitespace-pre-line text-[17px] font-normal leading-[160%] tracking-[-0.36px] text-ph-forest md:text-[20px] md:tracking-[-0.4px]">
    {children}
  </p>
);

const TimelineImage = ({
  title,
  isMobile = false,
  imageUrl
}: {
  title: string;
  isMobile?: boolean;
  imageUrl?: string;
}) => (
  <div className="transform transition-all duration-300 group-hover:translate-y-[-5px]">
    <img
      src={imageUrl}
      alt={title}
      className={cn(
        'w-full rounded-lg shadow-lg',
        isMobile ? 'mask mask-blob-16-9' : 'mask mask-blob-5-4'
      )}
    />
  </div>
);

const dataArrayObj = {
  'Complete your Functional Lab Panel to find the root cause': [
    {
      ...baseSanityNode,
      id: '1',
      _type: 'customText',
      heading: 'The Parsley Functional Lab Panel',
      _rawBody: {
        customText: (
          <div>
            <p className="text-ph-forest pt-0 pb-4">
              Let’s dive in. Parsley’s root cause-medicine trained physicians
              thoughtfully curated this advanced lab panel which tests 84
              biomarkers–which give a foundational look at what's happening
              beneath the surface. We’ve partnered with the top lab facilities
              like Labcorp and Bioreference across the country to give you a
              seamless experience from start to finish.
            </p>
            <Text
              style={{
                fontWeight: 700
              }}
              color="dark-green"
              variant="p3b"
              className="pt-0 pb-4"
            >
              The 84 key biomarkers we test for:
            </Text>
            <BulletList
              className="pt-0 pb-4 text-ph-forest"
              items={[
                'Comprehensive Metabolic Panel (Glucose, BUN, Creatinine, eFGR, BUN/Creatinine Ratio, Sodium, Potassium, Chloride, Carbon Dioxide (Total), Calcium, Protein (Total), Albumin, Globulin (Total), A/G Ratio, Bilirubin (Total), Alkaline Phosphatase, AST (SGOT), ALT (SGPT))',
                'CBC with differential (WBC, RBC, Hemoglobin, Hematocrit, MCV, MCH, MCHC, RDW, Platelets, Neutrophils, Lymphs, Monocytes, Eos, Basos, Neutrophils (Abs), Lymphs (Abs), Monocytes (Abs), Eos (Abs), Baso (Abs), Immature Granulocytes, Immature Grans (Abs), NRBC)',
                'TSH and Free T4',
                'Triiodothyronine (T3), Free',
                'Reverse T3',
                'Thyroglobulin Antibodies (TgAb)',
                'Thyroid Peroxidase Antibodies (TPO)',
                'High-Sensitivity C-Reactive Protein (CRP)',
                'Hemoglobin A1c (HbA1c)',
                'Insulin, fasting',
                'Ferritin',
                'Magnesium, RBC',
                'Vitamin D, 25-Hydroxy',
                'Vitamin B12',
                'Folate, RBC',
                'Sedimentation Rate, Modified Westergren',
                'γ-Glutamyl Transferase (GGT)',
                'Homocyst(e)ine',
                'Apo B',
                'NMR Lipoprofile with Lipids: Historical reporting (LDL-P, LDL-C); insulin-resistance score; lipoprotein particle number (LDL-P); particle concentration and size (total HDL-P, small LDL-P, LDL size); standard lipid panel (total cholesterol, calculated LDL cholesterol, HDL cholesterol, triglycerides)',
                'Omega Check: Omega 6/Omega 3 Ratio, Omega 3 and 6: Arachidonic Acid/EPA Ratio, Omega 3 and 6: Arachidonic Acid, Omega 3 and 6: EPA, Omega 3 and 6: DHA, Omega 3 (EPA+DHA) Index, Omega Risk'
              ]}
            />
          </div>
        )
      }
    },
    {
      ...baseSanityNode,
      id: '2',
      _type: 'customText',
      heading: 'Advanced Specialty Testing',
      _rawBody: {
        customText: (
          <div>
            <p className="text-ph-forest pt-0 pb-4">
              When it comes to treatment, one-size-doesn't-fit-all.
            </p>
            <p className="text-ph-forest pt-0 pb-4">
              After reviewing your initial results from the Functional Lab
              Panel, your Parsley-certified provider may recommend additional
              testing for things like hormones, gut health, or inflammation to
              help improve our understanding of your health and better inform
              your care. These optional tests are shipped conveniently to your
              home.
            </p>
            <p className="text-ph-forest pt-0 pb-4">
              While both Foundation and Total Health programs include your
              Functional Lab Panel, the Total Health program also includes one
              advanced specialty test, chosen based on recommendations from your
              clinician. (Eligible specialty tests include GI, SIBO, male/female
              hormones, stress & cortisol response, and food sensitivity.)
            </p>
          </div>
        )
      }
    }
  ],
  'Connect with functional-medicine-trained providers to get your plan': [
    {
      ...baseSanityNode,
      id: '4',
      _type: 'customText',
      heading: 'Get Care Anywhere',
      _rawBody: {
        customText: (
          <div>
            <p className="text-ph-forest pt-0 pb-4">
              Visit your board-certified clinician via secure video chat.
            </p>
            <p className="text-ph-forest pt-0 pb-4">
              Your Parsley clinician can order tests to your local lab and send
              prescriptions to your local pharmacy, making it more convenient
              than ever to get the care you need.
            </p>
          </div>
        )
      }
    },
    {
      ...baseSanityNode,
      id: '5',
      _type: 'customText',
      heading: 'Meet IRL in New York City and Los Angeles',
      _rawBody: {
        customText: (
          <div>
            <p className="text-ph-forest pt-0 pb-4">
              If you live in New York or California and prefer in-person care,
              we invite you to schedule appointments at our beautiful,
              best-in-class, and centrally-located medical centers.
            </p>
            <Link
              destination="/locations"
              variant="p3a"
              className="flex items-center pt-0 pb-4 text-ph-sage"
            >
              Visit a Parsley Health Center near you
              <ArrowIcon iconWidth="16" direction="right" />
            </Link>
          </div>
        )
      }
    }
  ],
  'Take action with your health coach and personalized meal plan': [
    {
      ...baseSanityNode,
      id: '7',
      _type: 'customText',
      heading: 'Health Coaching: It’s Easier With Help',
      _rawBody: {
        customText: (
          <div>
            <p className="text-ph-forest pt-0 pb-4">
              From meal planning to meditation, your Parsley coach is a
              highly-trained expert in root-cause medicine. They are here to
              support you in reaching your health goals.
            </p>
          </div>
        )
      }
    },
    {
      ...baseSanityNode,
      id: '8',
      _type: 'customText',
      heading: 'Meal Plans: Food as Medicine',
      _rawBody: {
        customText: (
          <div>
            <p className="text-ph-forest pt-0 pb-4">
              Your health coach will design a personalized plan tailored to your
              needs and metabolic goals.
            </p>
            <p className="text-ph-forest pt-0 pb-4">
              <strong>Each plan includes:</strong>
            </p>
            <BulletList
              className="pt-0 pb-4 text-ph-forest"
              items={[
                'A week’s worth of clean daily meals and snacks',
                '40 additional recipes unique to your health goals',
                'Shopping list',
                'A wide range of carefully chosen supplements & vitamins'
              ]}
            />
          </div>
        )
      }
    }
  ]
};

const TimelineContent = ({
  title,
  description,
  extraContent
}: {
  title: string;
  description: Description;
  extraContent?: { title: string; description: Description }[];
}) => {
  const [expandedItem, setExpandedItem] = useState<string | null>(null);

  return (
    <div className="flex transform flex-col gap-4 transition-all duration-300 md:gap-8">
      <TimelineTitle>{title}</TimelineTitle>
      <TimelineDescription>{description}</TimelineDescription>
      <Collapsible
        dataArray={dataArrayObj?.[title] || []}
        expandedItems={[expandedItem]}
        toggleItem={(id: string) =>
          setExpandedItem(expandedItem === id ? null : id)
        }
      />
    </div>
  );
};

const TimelineItem = ({
  title,
  description,
  imagePosition = 'right',
  imageUrl,
  isVisible,
  number,
  extraContent
}: TimelineItemProps) => (
  <div className="group my-16 flex w-full items-center">
    <div className="relative flex w-full flex-row items-start">
      <div className="circle-container relative z-20 flex items-start md:absolute md:left-1/2 md:-translate-x-1/2">
        <div
          className={`flex h-8 w-8 items-center justify-center rounded-full border border-ph-sage bg-[#FEF2F2] transition-all duration-500 md:h-16 md:w-16 ${
            isVisible ? 'scale-100' : 'scale-0'
          } sticky top-4`}
        >
          <span className="ph-font-teodor-regular text-sm font-medium text-ph-sage md:text-3xl">
            {number}
          </span>
        </div>
      </div>

      {/* Mobile Layout */}
      <div className="flex w-full flex-1 flex-col pl-4 md:hidden md:pl-0">
        <div
          className={`mb-4 w-full transition-all duration-1000 ${
            isVisible ? 'translate-y-0 opacity-100' : 'translate-y-8 opacity-0'
          }`}
        >
          <TimelineImage title={title} isMobile imageUrl={imageUrl} />
        </div>

        <div
          className={`w-full transition-all duration-1000 ${
            isVisible ? 'translate-y-0 opacity-100' : 'translate-y-8 opacity-0'
          }`}
        >
          <TimelineContent
            title={title}
            description={description}
            extraContent={extraContent}
          />
        </div>
      </div>

      {/* Desktop Layout */}
      <div className="hidden w-full items-start justify-between gap-8 md:flex">
        {imagePosition === 'left' ? (
          <>
            <div
              className={`w-[45%] transition-all duration-1000 ${
                isVisible
                  ? 'translate-y-0 opacity-100'
                  : 'translate-x-8 opacity-0'
              }`}
            >
              <TimelineImage title={title} imageUrl={imageUrl} />
            </div>
            <div
              className={`w-[45%] transition-all duration-1000 ${
                isVisible
                  ? 'translate-y-0 opacity-100'
                  : '-translate-x-8 opacity-0'
              }`}
            >
              <TimelineContent
                title={title}
                description={description}
                extraContent={extraContent}
              />
            </div>
          </>
        ) : (
          <>
            <div
              className={`w-[45%] transition-all duration-1000 ${
                isVisible
                  ? 'translate-y-0 opacity-100'
                  : 'translate-x-8 opacity-0'
              }`}
            >
              <TimelineContent
                title={title}
                description={description}
                extraContent={extraContent}
              />
            </div>
            <div
              className={`w-[45%] transition-all duration-1000 ${
                isVisible
                  ? 'translate-y-0 opacity-100'
                  : '-translate-x-8 opacity-0'
              }`}
            >
              <TimelineImage title={title} imageUrl={imageUrl} />
            </div>
          </>
        )}
      </div>
    </div>
  </div>
);

interface TimelineProps {
  rootMargin?: string;
  threshold?: number;
}

const Timeline = ({ rootMargin, threshold = 0.5 }: TimelineProps) => {
  const [visibleItems, setVisibleItems] = useState<Set<number>>(new Set([0]));
  const timelineRefs = useRef<(HTMLDivElement | null)[]>([]);
  const [lineHeight, setLineHeight] = useState(0);
  const [lineTop, setLineTop] = useState(0);

  useEffect(() => {
    timelineRefs.current = timelineRefs.current.slice(0, TIMELINE_DATA.length);

    const observerOptions = {
      root: null,
      rootMargin: rootMargin || '80% 0px -10% 0px',
      threshold: threshold || 0.1
    };

    const observerCallback = (entries: IntersectionObserverEntry[]) => {
      entries.forEach(entry => {
        const target = entry.target as HTMLElement;
        if (entry.isIntersecting && target.dataset.index) {
          setVisibleItems(
            prev => new Set([...prev, Number(target.dataset.index)])
          );
        }
      });
    };

    const observer = new IntersectionObserver(
      observerCallback,
      observerOptions
    );

    timelineRefs.current.forEach(ref => {
      if (ref) {
        observer.observe(ref);
      }
    });

    return () => observer.disconnect();
  }, [rootMargin, threshold]);

  useEffect(() => {
    // Calculate line position and height based on visible items
    const lastVisibleIndex = Math.max(...Array.from(visibleItems));
    const firstElement = timelineRefs.current[0]?.querySelector(
      '.circle-container'
    );
    const lastElement = timelineRefs.current[lastVisibleIndex]?.querySelector(
      '.circle-container'
    );
    const arrowNode = document.querySelector('.arrow-node');

    if (firstElement && (lastElement || arrowNode)) {
      const firstRect = firstElement.getBoundingClientRect();
      const lastRect =
        lastVisibleIndex === TIMELINE_DATA.length - 1 && arrowNode
          ? arrowNode.getBoundingClientRect()
          : lastElement?.getBoundingClientRect() || { top: 0, height: 0 };
      const containerRect = timelineRefs.current[0]?.parentElement?.getBoundingClientRect();

      if (containerRect) {
        const top = firstRect.top - containerRect.top + firstRect.height / 2;
        const height = lastRect.top - firstRect.top + lastRect.height / 2;
        setLineTop(top);
        setLineHeight(height);
      }
    }
  }, [visibleItems]);

  return (
    <div className="text-teodor relative w-full bg-[#FEF2F2] px-6 py-16 md:px-16 md:py-24">
      <h1 className="ph-font-teodor-regular mb-14 text-center text-[42px] font-normal leading-[120%] tracking-[-0.42px] text-ph-forest md:mb-24 md:mt-0 md:text-[50px] md:tracking-[-0.5px]">
        Start your health journey here
      </h1>

      <div className="absolute bottom-0 left-[39px] top-0 w-[1px] md:left-1/2 md:-translate-x-1/2">
        <div
          className="absolute w-full bg-gray-200"
          style={{
            top: `${lineTop}px`,
            height: `${lineHeight}px`
          }}
        />
        <div
          className="absolute w-full bg-ph-sage transition-all duration-1000 ease-out"
          style={{
            top: `${lineTop}px`,
            height: `${lineHeight}px`,
            opacity: visibleItems.has(1) ? 1 : 0
          }}
        />
      </div>

      {TIMELINE_DATA.map((item, index) => (
        <div
          key={index}
          ref={el => (timelineRefs.current[index] = el)}
          data-index={index}
        >
          <TimelineItem
            title={item.title}
            description={item.description}
            extraContent={item.extraContent}
            imagePosition={item.imagePosition}
            isVisible={visibleItems.has(index)}
            imageUrl={item.imageUrl}
            number={String(index + 1).padStart(2, '0')}
          />
        </div>
      ))}

      <div className="relative flex flex-row items-center gap-4 md:flex-col md:items-center">
        <div
          className={`arrow-node absolute left-0 flex h-8 w-8 items-center justify-center rounded-full border border-ph-sage bg-[#FEF2F2] transition-all duration-500 md:static md:mb-0 md:h-16 md:w-16 ${
            visibleItems.has(TIMELINE_DATA.length - 1) ? 'scale-100' : 'scale-0'
          }`}
        >
          <svg
            className="h-4 w-4 rotate-[-90deg] text-ph-sage md:h-6 md:w-6 md:rotate-0"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M19 14l-7 7m0 0l-7-7m7 7V3"
            />
          </svg>
        </div>

        <Button
          trackingLabel="Schedule"
          size="large"
          variant="filled"
          className="mt-8 inline-block rounded-full bg-ph-forest px-8 py-4 text-[16px] font-medium text-white transition hover:bg-ph-deep-sea md:mt-12"
          destination="/contact/free-consultation"
        >
          Schedule A Call
        </Button>
      </div>
    </div>
  );
};

export default Timeline;
