import React from 'react';
import { ProviderTeam } from '../../data/new/homepage';
import { Button, Text } from '../../redesign';

interface ActionProps {
  value: string;
  buttonLink: string;
}

interface ProviderProps {
  heading: string;
  subheading?: string;
  className?: string;
  data?: { src: string; name: string; title: string }[];
  primaryAction?: ActionProps;
}
const ProviderGrid = ({
  heading,
  subheading,
  data,
  primaryAction,
  className
}: ProviderProps) => {
  return (
    <section className="flex flex-col px-6 py-16 text-center md:px-16 items-center justify-center">
      <div className="flex w-full flex-col items-center justify-center gap-6">
        <Text className="mb-4" color="dark-green" variant="h9a">
          {heading}
        </Text>
        {subheading && (
          <h2 className="text-ph-subtitle mx-auto max-w-[654px]">
            {subheading}
          </h2>
        )}
      </div>

      {/* Uncomment the following line to use the filter */}
      {/* <ProviderFilter /> */}

      <div className="grid grid-cols-2 gap-16 py-8 md:grid-cols-2 md:py-16 lg:grid-cols-4">
        {data &&
          data?.map((img, index) => (
            <div key={index} className="flex flex-col">
              <img
                src={img.src}
                className="mask mask-blob-1-1 aspect-[1/1]"
                alt={img.name}
              />
              <Text
                variant="p4a"
                className="text-ph-sage mt-6 mb-2 text-center text-[11px] font-medium leading-[11px] tracking-[0.88px] uppercase"
              >
                {img.title}
              </Text>
              <Text className="my-0" color="dark-green" variant="h7a">
                {img.name}
              </Text>
            </div>
          ))}
      </div>
      {primaryAction && (
        <Button
          trackingLabel={primaryAction.value}
          onClick={() => (window.location.href = primaryAction.buttonLink)}
          {...primaryAction}
        >
          {primaryAction?.value}
        </Button>
      )}
    </section>
  );
};

export const ProviderGridModule = ({
  heading,
  subheading,
  className
}: ProviderProps) => (
  <ProviderGrid
    heading={heading}
    subheading={subheading}
    data={ProviderTeam}
    primaryAction={{
      value: 'Learn More',
      buttonLink: '/providers'
    }}
    className={className}
  />
);
