import React from 'react';

import { IconProps } from '../../../types/index';
import { IconSvg } from '../icon-svg/IconSvg';

export const InfoIcon: React.FC<IconProps> = ({
  strokeWidth = 3,
  color = '#6FB39F',
  ...props
}) => {
  return (
    <IconSvg viewBox="0 0 14 13" fill="none" {...props}>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.25 12.04C4.21243 12.04 1.75 9.5776 1.75 6.54004C1.75 3.50247 4.21243 1.04004 7.25 1.04004C10.2876 1.04004 12.75 3.50247 12.75 6.54004C12.75 9.5776 10.2876 12.04 7.25 12.04ZM0.75 6.54004C0.75 10.1299 3.66015 13.04 7.25 13.04C10.8399 13.04 13.75 10.1299 13.75 6.54004C13.75 2.95019 10.8399 0.0400391 7.25 0.0400391C3.66015 0.0400391 0.75 2.95019 0.75 6.54004ZM7.75001 9.54004V5.94004H6.75001V9.54004H7.75001ZM7.85001 4.14004C7.85001 3.80867 7.58138 3.54004 7.25001 3.54004C6.91864 3.54004 6.65001 3.80867 6.65001 4.14004C6.65001 4.47141 6.91864 4.74004 7.25001 4.74004C7.58138 4.74004 7.85001 4.47141 7.85001 4.14004Z"
        fill={color}
        strokeWidth={strokeWidth}
      />
    </IconSvg>
  );
};
