import { StructuredProps } from '../../redesign/types';
import React from 'react';
import IconAtom from '../../assets/atom.svg';
import IconInsurance from '../../assets/insurance.svg';
import IconTreatmentPlan from '../../assets/treatment_plan.svg';

export const StructuredData: StructuredProps = {
  alignment: 'center',
  items: [
    {
      id: 'structured-improve',
      heading: 'What’s included for \n in-network plans',
      description:
        'If in-network, all visits with your Parsley Health medical provider will be billed to your insurance. Depending on your insurance plan, you may pay a copay upfront, or pay for visits until you hit your deductible.',
      illustration: <IconAtom />
    },
    {
      id: 'structured-reduce',
      heading: 'What you’ll pay',
      description:
        'If in-network, all visits with your Parsley Health medical provider will be billed to your insurance. Depending on your insurance plan, you may pay a copay upfront, or pay for visits until you hit your deductible.',
      illustration: <IconInsurance />
    },
    {
      id: 'structured-reduce',
      heading: 'We’ll handle the paperwork',
      description:
        'If you’re eligible to use insurance, don’t stress about sending claims to your insurance company. Parsley Health takes all the paperwork and billing off your plate.',
      illustration: <IconTreatmentPlan />
    }
  ]
};
