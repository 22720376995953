import React from 'react';
import { Button } from '../../redesign';

export function HowWeWorkHero() {
  return (
    <section className="relative max-h-[944px] min-h-[558px] rounded-b-[96px] bg-neutral-50 px-4">
      <div
        className="bg-how-we-work-hero-image absolute inset-0 z-0 h-full w-full rounded-b-[96px]"
        style={{
          backgroundImage:
            "url('https://cdn.sanity.io/images/mur2ep80/production/a0fecb42f06822f1da9add5ea72f6f0b624de353-3500x2100.webp')",
          backgroundPosition: 'center 100%',
          backgroundSize: 'cover'
        }}
      />

      <div className="text-teodor relative mx-auto max-w-7xl">
        <div className="flex flex-col-reverse md:flex-row md:justify-end">
          <div className="rounded-2xl px-4 py-8 md:w-2/3 md:px-8 md:py-16 lg:w-1/2">
            <h1 className="text-ph-hero">Your road map to ultimate health</h1>
            <p className="text-ph-subtitle mt-4 md:mt-6">
              Your functional medicine journey begins with comprehensive
              diagnostic testing, peeling back the layers of your health to get
              to the root cause of your symptoms. Then, you&apos;ll receive a
              plan to get you on track—and back in control of your health.
            </p>
            <Button
              trackingLabel="Schedule"
              size="large"
              variant="filled"
              className="mt-8 inline-block rounded-full bg-ph-forest px-8 py-4 text-[16px] font-medium text-white transition hover:bg-ph-deep-sea md:mt-12"
              destination="/contact/free-consultation"
            >
              Schedule A Call
            </Button>
          </div>
        </div>
      </div>
    </section>
  );
}
