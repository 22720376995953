export enum PaymentType {
  IN_NETWORK = 'In-Network',
  OUT_OF_NETWORK = 'Out-of-Network: Self-Pay'
}

export enum PlanType {
  FOUNDATION = 'Foundation',
  TOTAL_HEALTH = 'Total Health'
}

export enum BillingType {
  MONTHLY = 'Monthly',
  YEARLY = 'Yearly'
}

const STRIPE_LINKS_STAGE = {
  [PlanType.FOUNDATION]: {
    [BillingType.YEARLY]: 'https://buy.stripe.com/test_14k7uVbAO8hQ8uIeUU',
    [BillingType.MONTHLY]: 'https://buy.stripe.com/test_bIY16xbAO7dM12g7st'
  },
  [PlanType.TOTAL_HEALTH]: {
    [BillingType.YEARLY]: 'https://buy.stripe.com/test_6oEeXn34i41AfXafZ1',
    [BillingType.MONTHLY]: 'https://buy.stripe.com/test_8wM2aBfR469I9yMaEG'
  }
};

const STRIPE_LINKS_PROD = {
  [PlanType.FOUNDATION]: {
    [BillingType.YEARLY]: 'https://buy.stripe.com/dR62898Ok13N4IUeUV',
    [BillingType.MONTHLY]: 'https://buy.stripe.com/fZe1452pW13N3EQ3ce'
  },
  [PlanType.TOTAL_HEALTH]: {
    [BillingType.YEARLY]: 'https://buy.stripe.com/dR65kl3u05k31wI9AD',
    [BillingType.MONTHLY]: 'https://buy.stripe.com/aEUeUV3u0h2L8Za28c'
  }
};

export const getStripeLink = (
  planType: PlanType,
  billing: BillingType
): string => {
  const IS_PRODUCTION = process.env.GATSBY_ACTIVE_ENV === 'production';
  const stripeLinks = IS_PRODUCTION ? STRIPE_LINKS_PROD : STRIPE_LINKS_STAGE;
  return stripeLinks[planType][billing];
};
