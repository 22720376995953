import React, { useEffect, useState } from 'react';
import { AETNA_SLUG } from '../../../../constants';
import { Button } from '../../..';
import { LayoutTheme, TButtonColor } from '../../../types';

interface JoinButtonProps {
  theme?: LayoutTheme;
  variant?: 'desktop' | 'mobile';
}

const themeButtonVariant: Record<LayoutTheme, TButtonColor> = {
  sherbed: 'darkGreen',
  ivory: 'darkGreen',
  darkGreen: 'white'
};

export const JoinButton: React.FC<JoinButtonProps> = ({ theme }) => {
  const [currentPath, setCurrentPath] = useState('');

  useEffect(() => {
    setCurrentPath(window.location.pathname);
  }, []);

  const currentIsJoin = currentPath.includes('/membership');
  const currentIsAetnaLP = currentPath.includes(`/${AETNA_SLUG}`);
  const JOIN_APP_URL = process.env.GATSBY_JOIN_APP_URL;

  const trackingLabel = `Schedule A Call button on top navigation bar of ${currentPath} page`;

  const handleOnClick = () => {
    window.heap.track('Schedule A Call');
  };

  return (
    <Button
      color={theme ? themeButtonVariant[theme] : 'darkGreen'}
      variant="filled"
      size="small"
      onClick={handleOnClick}
      destination={currentIsJoin ? '/contact' : '/contact/free-consultation/'}
      trackingCategory={currentIsAetnaLP ? 'Learn More' : undefined}
      trackingLabel={currentIsAetnaLP ? 'topNav' : trackingLabel}
    >
      {currentIsJoin ? 'Talk to Us' : 'Schedule A Call'}
    </Button>
  );
};
