import { useState, useCallback, useEffect } from 'react';

export const useMediaQuery = (viewport: string) => {
  const [match, setMatch] = useState<boolean>();

  const onMediaChange = useCallback((e: MediaQueryListEvent) => {
    if (e.matches) {
      setMatch(true);
    } else {
      setMatch(false);
    }
  }, []);

  useEffect(() => {
    const media = window.matchMedia(`(max-width: calc(${viewport} - 1px)`);

    // Check screen size on component mount
    if (media.matches) {
      setMatch(true);
    }

    // Check screen size on media change
    media.addEventListener('change', onMediaChange);

    return () => media.removeEventListener('change', onMediaChange);
  }, [onMediaChange, viewport]);

  return match;
};
