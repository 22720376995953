import React, { useState } from 'react';
import clsx from 'clsx';
import { Button } from '../../redesign';
import { Tooltip } from '@parsleyhealth/cilantro-ui';
import { ArrowIcon } from '../../redesign/atoms/icons/arrow-icon/arrow-icon';

enum PaymentType {
  IN_NETWORK = 'In-Network',
  OUT_OF_NETWORK = 'Out-of-Network: Self-Pay'
}

const commonPricingItems = [
  <span key="foundationItem1" className="font-medium">
    1 Annual Functional Lab Panel
  </span>,
  <span key="foundationItem2" className="font-medium">
    Personalized Treatment Plan
  </span>,
  'Personalized Meal Plan'
];

const commonFoundationItems = [
  '1 Health Coaching Session',
  'On-Going Messaging Support From Your Team'
];

const commonTotalHealthItems = [
  'Meal Plan',
  '12 Health Coaching Sessions',
  'On-Going Messaging Support From Your Team'
];

const vitaminDiscountText = (
  <div key="discount">
    15% off &nbsp;
    <a
      href="https://store.parsleyhealth.com/"
      target="_blank"
      rel="noreferrer"
      className="text-ph-sage underline"
    >
      Vitamin & Supplements
    </a>
  </div>
);

const telemedicinText =
  '1 Telemedicine Visit with Clinician Trained In Root-Cause Medicine*';

const specialtyTestText = (
  <div className="flex items-center gap-1">
    <div>1 Advanced Specialty Test</div>
    <Tooltip
      place="left"
      content="Eligible specialty tests include GI, SIBO, male/female hormones,
            stress & cortisol response, and food sensitivity."
    />
  </div>
);

const listItems = {
  [PaymentType.IN_NETWORK]: [
    {
      title: 'Foundation',
      price: '$1,299',
      items: [
        ...commonPricingItems,
        ...commonFoundationItems,
        vitaminDiscountText
      ]
    },
    {
      title: 'Total Health',
      price: '$2,999',
      items: [
        ...commonPricingItems,
        ...commonTotalHealthItems,
        vitaminDiscountText,
        specialtyTestText
      ]
    }
  ],
  [PaymentType.OUT_OF_NETWORK]: [
    {
      title: 'Foundation',
      price: '$1,599',
      items: [
        ...commonPricingItems,
        ...commonFoundationItems,
        telemedicinText,
        vitaminDiscountText
      ]
    },
    {
      title: 'Total Health',
      price: '$3,299',
      items: [
        ...commonPricingItems,
        ...commonTotalHealthItems,
        telemedicinText,
        vitaminDiscountText,
        specialtyTestText
      ]
    }
  ]
};

const CheckmarkIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        d="M6 12.7485L10.5 16.5L18 7.5"
        stroke="#4F7F71"
        strokeWidth="1.2"
        strokeMiterlimit="10"
      />
    </svg>
  );
};

export const Pricing = () => {
  const [paymentType, setPaymentType] = useState<PaymentType>(
    PaymentType.IN_NETWORK
  );

  const togglePaymentType = () => {
    setPaymentType(
      paymentType === PaymentType.IN_NETWORK
        ? PaymentType.OUT_OF_NETWORK
        : PaymentType.IN_NETWORK
    );
  };
  return (
    <div className="flex flex-col gap-8 text-teodor bg-ph-gradient-mint px-6 py-16 md:gap-16 md:px-16 md:py-24">
      {/* Header */}
      <div className="mx-auto text-center text-ph-forest lg:max-w-[700px]">
        <div className="text-ph-h1 pb-4">
          Optimize your health with clarity and precision
        </div>
        <div className="text-ph-subtitle">
          Transparent pricing for a long-term partnership that combines the best
          of healthcare with science-backed, functional medicine.
        </div>
      </div>
      {/* Card */}
      <div className="mx-auto flex w-full flex-col items-center gap-6 rounded-xl bg-white px-4 py-6 md:max-w-[928px] md:gap-12 md:px-12 md:py-8">
        {/* Tabs */}
        <div className="flex">
          <div className="bg-ph-gradient-mint-light flex gap-1 rounded-[10px] border border-ph-mint p-0.5">
            {[PaymentType.IN_NETWORK, PaymentType.OUT_OF_NETWORK].map(type => {
              return (
                <button
                  key={type}
                  onClick={togglePaymentType}
                  disabled={paymentType === type}
                  className={clsx(
                    'py-1.5',
                    'px-3',
                    'md:px-8',
                    'bg-transparent',
                    'disabled:bg-white',
                    'disabled:font-medium',
                    'disabled:shadow-[0_2px_10px_0_rgba(0,0,0,0.12)]',
                    'text-ph-p2',
                    'text-ph-forest',
                    'xl:text-base',
                    'rounded-[10px]'
                  )}
                >
                  {type}
                </button>
              );
            })}
          </div>
        </div>
        {/* Pricing body */}
        <div
          key={`pricePaymentType-${paymentType}`}
          className="grid w-full grid-cols-1 flex-col divide-y divide-ph-dark-grey md:grid-cols-2 md:flex-row md:divide-x md:divide-y-0"
        >
          {listItems[paymentType].map(priceModule => {
            return (
              <div
                key={`priceModule${paymentType}`}
                className="flex-grow first:pb-6 last:pt-6 md:first:pb-0 md:first:pr-12 md:last:pl-12 md:last:pt-0"
              >
                <div className="flex items-center justify-between pb-4 md:pb-6">
                  <div className="text-ph-h3 text-ph-forest">
                    {priceModule.title}
                  </div>
                  <div className="text-ph-h5 font-medium !text-[#4A786A] md:text-2.5xl md:leading-[120%] md:tracking-[-0.56px]">
                    {priceModule.price}
                  </div>
                </div>
                <div className="text-ph-h6 pb-2 font-medium text-ph-forest">
                  What’s included:
                </div>
                <ul className="w-ful flex flex-col gap-2">
                  {priceModule.items.map((item, i) => {
                    return (
                      <li
                        key={`priceList${i}`}
                        className="text-ph-p2 flex gap-1 text-ph-forest"
                      >
                        <CheckmarkIcon className="min-w-6" /> {item}
                      </li>
                    );
                  })}
                </ul>
              </div>
            );
          })}
        </div>
        {/* Disclaimer */}
        <div className="text-ph-caption !text-ph-light-grey">
          {paymentType === PaymentType.IN_NETWORK ? (
            <p className="text-start md:text-center">
              Medical visits with a Parsley Health clinician are a separate fee.
              If you’re in-network, your medical visits will be billed to your
              insurance. You are responsible for understanding your insurance
              benefits and are responsible for patient share of cost not paid by
              insurance which may include copayments, coinsurance, and
              deductibles.
            </p>
          ) : (
            <div className="text-start">
              *Additional medical visits are not included in the program fee. If
              you’re not in-network and paying out-of-pocket, our visit fees
              are:
              <ul className="list-inside list-disc pt-2">
                <li>First 60-minute medical visit: Included</li>
                <li>Second 60-minute medical visit: $399</li>
                <li>Follow-up 30-minute medical visits: $249</li>
              </ul>
            </div>
          )}
        </div>
      </div>
      <div className="flex justify-center text-center">
        <Button
          trackingLabel="Pricing & Insurance Details"
          variant="filled"
          size="large"
          destination="/pricing-and-insurance"
          className="text-base tracking-[-0.16px]"
        >
          Pricing & Insurance Details{' '}
          <ArrowIcon direction="right" outlined className="ml-2" />
        </Button>
      </div>
    </div>
  );
};
