import React from 'react';
import { Link } from 'react-router-dom';
import { ArrowIcon } from '../../../redesign/molecules/icons';

export type Description = string | JSX.Element;

export interface TimelineDataItem {
  title: string;
  description: Description;
  imagePosition: 'left' | 'right';
  imageUrl?: string;
  extraContent?: { title: string; description: Description }[];
}

export const TIMELINE_DATA: TimelineDataItem[] = [
  {
    title: 'Complete your Functional Lab Panel to find the root cause',
    description: (
      <div className="space-y-2">
        <p>
          To find the root cause of your symptoms, it&apos;s crucial to look at
          your data.
        </p>

        <p>
          Every program at Parsley includes our Functional Lab Panel, the most
          curated and comprehensive set of functional lab tests. It&apos;s an
          annual diagnostic that measures over 60 key biomarkers traditional
          healthcare often ignores.
        </p>

        <p>
          From there, we offer additional tests that cover more than 1000 other
          biomarkers through blood work and at-home specialty tests spanning gut
          health, hormone health, and immunity. Your medical provider will guide
          you on which tests are right for you.
        </p>
      </div>
    ),
    imagePosition: 'left',
    imageUrl:
      'https://cdn.sanity.io/images/mur2ep80/production/115339538ed371cfa13dfa1c00a97e9bfcb71c03-4096x2730.webp',
    extraContent: [
      {
        title: 'The Parsley Functional Lab Panel',
        description: (
          <>
            <p>
              Let&apos;s dive in. Parsley&apos;s root cause-medicine trained
              physicians thoughtfully curated this advanced lab panel which
              tests 84 biomarkers–which give a foundational look at what&apos;s
              happening beneath the surface.
            </p>

            <p>
              We&apos;ve partnered with the top lab facilities like Labcorp and
              Bioreference across the country to give you a seamless experience
              from start to finish.
            </p>

            <p className="font-bold">The 84 key biomarkers we test for:</p>
            <ul className="ml-3 mt-4 list-disc pl-6">
              <li>
                Comprehensive Metabolic Panel (Glucose, BUN, Creatinine, eFGR,
                BUN/Creatinine Ratio, Sodium, Potassium, Chloride, Carbon
                Dioxide (Total), Calcium, Protein (Total), Albumin, Globulin
                (Total), A/G Ratio, Bilirubin (Total), Alkaline Phosphatase, AST
                (SGOT), ALT (SGPT))
              </li>
              <li>
                CBC with differential (WBC, RBC, Hemoglobin, Hematocrit, MCV,
                MCH, MCHC, RDW, Platelets, Neutrophils, Lymphs, Monocytes, Eos,
                Basos, Neutrophils (Abs), Lymphs (Abs), Monocytes (Abs), Eos
                (Abs), Baso (Abs), Immature Granulocytes, Immature Grans (Abs),
                NRBC)
              </li>
              <li>TSH and Free T4</li>
              <li>Triiodothyronine (T3), Free</li>
              <li>Reverse T3</li>
              <li>Thyroglobulin Antibodies (TgAb)</li>
              <li>Thyroid Peroxidase Antibodies (TPO)</li>
              <li>High-Sensitivity C-Reactive Protein (CRP)</li>
              <li>Hemoglobin A1c (HbA1c)</li>
              <li>Insulin, fasting</li>
              <li>Ferritin</li>
              <li>Magnesium, RBC</li>
              <li>Vitamin D, 25-Hydroxy</li>
              <li>Vitamin B12</li>
              <li>Folate, RBC</li>
              <li>Sedimentation Rate, Modified Westergren</li>
              <li>γ-Glutamyl Transferase (GGT)</li>
              <li>Homocyst(e)ine</li>
              <li>Apo B</li>
              <li>
                NMR Lipoprofile with Lipids: Historical reporting (LDL-P,
                LDL-C); insulin-resistance score; lipoprotein particle number
                (LDL-P); particle concentration and size (total HDL-P, small
                LDL-P, LDL size); standard lipid panel (total cholesterol,
                calculated LDL cholesterol, HDL cholesterol, triglycerides)
              </li>
              <li>
                Omega Check: Omega 6/Omega 3 Ratio, Omega 3 and 6: Arachidonic
                Acid/EPA Ratio, Omega 3 and 6: Arachidonic Acid, Omega 3 and 6:
                EPA, Omega 3 and 6: DHA, Omega 3 (EPA+DHA) Index, Omega Risk
              </li>
            </ul>
          </>
        )
      },
      {
        title: 'Advanced Specialty Testing',
        description: (
          <>
            <p>When it comes to treatment, one-size-doesn&apos;t-fit-all.</p>

            <p>
              After reviewing your initial results from the Functional Lab
              Panel, your Parsley-certified provider may recommend additional
              testing for things like hormones, gut health, or inflammation to
              help improve our understanding of your health and better inform
              your care. These optional tests are shipped conveniently to your
              home.
            </p>

            <p>
              While both Foundation and Total Health programs include your
              Functional Lab Panel, the Total Health program also includes one
              advanced specialty test, chosen based on recommendations from your
              clinician. (Eligible specialty tests include GI, SIBO, male/female
              hormones, stress & cortisol response, and food sensitivity.)
            </p>
          </>
        )
      }
    ]
  },
  {
    title:
      'Connect with functional-medicine-trained providers to get your plan',
    description: (
      <div className="space-y-2">
        <p>
          You&apos;ve got your lab results, now it&apos;s time to partner with a
          Parsley clinician.
        </p>

        <p>
          In your hour-long first visit, you&apos;ll go over your results
          in-depth, get all your questions answered, and design a personalized
          treatment plan that goes beyond medication to include nutrition,
          fitness, stress management, supplements, and more.
        </p>

        <p>
          Our board-certified providers are trained at top hospitals across the
          US and–unlike anywhere else–they&apos;re uniquely trained in
          functional medicine through our extensive root-cause medicine
          certification program before joining our team.
        </p>
      </div>
    ),
    imagePosition: 'right',
    imageUrl:
      'https://cdn.sanity.io/images/mur2ep80/production/cac806afaea8031ec1c429dfcbf56771f200e3fc-3750x2500.webp',
    extraContent: [
      {
        title: 'Get Care Anywhere',
        description:
          'Visit your board-certified clinician via secure video chat.\n\n' +
          'Your Parsley clinician can order tests to your local lab and send prescriptions to your local pharmacy, making it more convenient than ever to get the care you need.'
      },
      {
        title: 'Meet IRL in New York City and Los Angeles',
        description: (
          <>
            If you live in New York or California and prefer in-person care, we
            invite you to schedule appointments at our beautiful, best-in-class,
            and centrally-located medical centers.
            <Link
              to="/locations"
              className="flex items-center gap-1 text-ph-sage"
            >
              Visit a Parsley Health Center near you
              <ArrowIcon iconWidth="48" direction="right" circled />
            </Link>
          </>
        )
      }
    ]
  },
  {
    title: 'Take action with your health coach and personalized meal plan',
    description:
      'At Parsley, we pair you with health coaches certified in root-cause medicine to help you navigate your care plan, plus, they’ll create a personalized, detailed meal plan curated to your health goals.',
    imagePosition: 'left',
    imageUrl:
      'https://cdn.sanity.io/images/mur2ep80/production/558396f014fe25c1dbe5c1cc041a073436561160-3750x2500.webp',
    extraContent: [
      {
        title: "Health Coaching: It's Easier With Help",
        description:
          'From meal planning to meditation, your Parsley coach is a highly-trained expert in root-cause medicine. They are here to support you in reaching your health goals.'
      },
      {
        title: 'Meal Plans: Food as Medicine',
        description: (
          <>
            <p>
              Your health coach will design a personalized plan tailored to your
              needs and metabolic goals.
            </p>

            <p>Each plan includes:</p>
            <ul className="ml-3 mt-4 list-disc pl-6">
              <li>{`A week's worth of clean daily meals and snacks`}</li>
              <li>40 additional recipes unique to your health goals</li>
              <li>Shopping list</li>
              <li>A wide range of carefully chosen supplements & vitamins</li>
            </ul>
          </>
        )
      }
    ]
  },
  {
    title: 'Year-round, ongoing support from your clinical and coach team',
    description: (
      <>
        For the duration of your program, your health coaches and clinical team
        are available to answer any and all questions as they come up. They also
        help with:
        <ul className="ml-3 mt-4 list-disc pl-6">
          <li>Refills and pre-authorizations</li>
          <li>Getting labs done</li>
          <li>Insurance</li>
          <li>Scheduling</li>
          <li>Specialist referrals</li>
          <li>Questions about your treatment plan</li>
        </ul>
      </>
    ),
    imagePosition: 'right',
    imageUrl:
      'https://cdn.sanity.io/images/mur2ep80/production/f02d4b6dfaeaa5266cd036ed70d83d24fcd4b38f-3750x2500.webp'
  },
  {
    title: 'Check your progress again—and again ',
    description: (
      <>
        <strong>{`We don't do short-term fixes.`}</strong> Consistency is key to
        achieving good health that lasts.
        {'\n\n'}
        We encourage regular testing, including the annual Parsley Functional
        Lab Panel, to help track your progress—and see your results!
      </>
    ),
    imagePosition: 'left',
    imageUrl:
      'https://cdn.sanity.io/images/mur2ep80/production/8a44e75d71a80adeddce26d60ce37d7a16c02adb-3750x2500.webp'
  }
];
