import React from 'react';
import { Link } from 'gatsby';
import LogoFile from '../../../../assets/logo-horizontal.svg';
import styles from './logo.module.css';
import clsx from 'clsx';
import { useNewProgram } from '@parsleyhealth/cilantro-ui';

interface LogoProps {
  variant?: 'desktop' | 'mobile';
}

export const Logo: React.FC<LogoProps> = ({ variant = 'desktop' }) => {
  const { isAllowed } = useNewProgram(false, '/');
  return (
    <Link to={!isAllowed ? '/new' : '/'}>
      <LogoFile
        role="img"
        aria-label="Parsley Health Home"
        className={clsx(styles.logo, styles[variant])}
      />
    </Link>
  );
};
