import React from 'react';
import { useMediaQuery } from '../utils/hooks/use-media-query';
import { clsx } from 'clsx';

interface QuoteCarouselNavBarProps {
  quotes: { quote: string; quoteSource: string | JSX.Element }[];
  activeIndex: number;
  handleTransition: (index: number) => void;
  isCustomerQuote: boolean;
  navColor: string;
  eyebrow?: string;
}

export function QuoteCarouselNavBar({
  quotes,
  activeIndex,
  handleTransition,
  isCustomerQuote,
  navColor,
  eyebrow
}: QuoteCarouselNavBarProps) {
  const isMobile = useMediaQuery('768px');
  if (eyebrow || isMobile) {
    return (
      <div
        className={`mt-6 flex justify-center ${
          isCustomerQuote ? 'space-x-2' : ''
        }`}
      >
        {quotes.map((_, index) => (
          <button
            key={index}
            onClick={() => handleTransition(index)}
            className="py-2"
          >
            <span
              className={`mb-16 block h-[4px] w-[68px] md:rounded-full transition-all duration-300 md:mb-24 ${
                index === activeIndex ? 'bg-ph-forest' : navColor
              }`}
            />
          </button>
        ))}
      </div>
    );
  } else {
    return (
      <div
        className={`mt-6 flex flex-col items-center ${
          isCustomerQuote ? 'space-x-2' : ''
        }`}
      >
        <div className="flex w-full max-w-[697px] flex-col items-center">
          <div className="flex w-full justify-center">
            {quotes.map((quote, index) => (
              <div key={index}>
                <button
                  onClick={() => handleTransition(index)}
                  className="py-2"
                >
                  <div
                    key={index}
                    className={clsx(
                      'mb-8 flex h-8 max-h-[34px] items-center justify-center overflow-hidden md:mb-6 md:h-10 md:px-4',
                      index === 0 && 'md:pl-0',
                      index === 5 && 'md:pr-0',
                      index === activeIndex ? 'text-ph-forest' : 'opacity-50'
                    )}
                  >
                    {quote.quoteSource}
                  </div>
                  <span
                    className={`mb-16 block h-[4px] md:mb-24 ${
                      index === activeIndex ? 'bg-ph-forest' : navColor
                    }`}
                  />
                </button>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
}
