import React, { useEffect } from 'react';
import '../global-styles/index.scss';

import { FakeHelpScoutButton } from '../../components/ThirdParty/fake-helpscout-button';
import { useNewProgram, newProgramRedirect } from '@parsleyhealth/cilantro-ui';
import { useHelpscoutButton } from '../../hooks';
import NavSwitcher, { NavbarType } from './nav-switcher';
import FooterSwitcher, { FooterType } from './footer-switcher';
import { BodyContainer } from '../atoms/body-container';
import { Mask } from '../atoms/mask/mask';
import MaskProvider from '../context/mask-provider';
import { FooterCtaProps } from '../molecules/footer-cta/footer-cta';
import { DigiohScript } from '../../components/ThirdParty/digioh-script';

const isThirdPartyDisabled =
  process.env.GATSBY_DISABLE_THIRD_PARTY_INTEGRATIONS === 'true';

export type LayoutTheme = 'sherbed' | 'ivory' | 'darkGreen';

export interface LayoutProps extends FooterCtaProps {
  theme?: LayoutTheme;
  navbarType?: NavbarType;
  footerType?: FooterType;
  hideQuizCtaButton?: boolean;
  customNav?: React.ReactNode;
  children?;
}

// Tech Debt: Refactor Layout so that we use separate layouts
// for insights and b2b. Having things like isInsights or
// isB2B really go against separation of concerns, these components
// shouldn't be aware of the type of page they belong to but rather
// serve a specific type of layout and internally the layouts should be
// built with reusable components.
export const Layout: React.FC<LayoutProps> = ({
  children,
  footerContentKey,
  footerType = 'MAIN_SITE',
  theme = 'sherbed',
  navbarType = 'MAIN_SITE',
  hideQuizCtaButton = false,
  customNav
}) => {
  const { onInitHelpScout, color } = useHelpscoutButton();
  const { isLoading, isAllowed, isDisabled } = useNewProgram();
  const isInsights = navbarType === 'INSIGHTS';
  const isB2B = navbarType === 'B2B';

  useEffect(() => {
    if (isDisabled) {
      return;
    }

    if (!isAllowed) {
      newProgramRedirect();
    }
  }, [isAllowed, isDisabled, isLoading]);

  return (
    <main className={`bg-primary theme-${theme}`}>
      <DigiohScript />
      {!isThirdPartyDisabled && (
        <FakeHelpScoutButton onInitHelpScout={onInitHelpScout} color={color} />
      )}
      {!isLoading && (
        <MaskProvider>
          <Mask />
          <NavSwitcher
            theme={theme}
            navbarType={navbarType}
            hideQuizCtaButton={hideQuizCtaButton}
          >
            {customNav}
          </NavSwitcher>
          <BodyContainer isInsights={isInsights} isB2B={isB2B}>
            {children}
          </BodyContainer>
          <FooterSwitcher
            footerContentKey={footerContentKey}
            footerType={footerType}
          />
        </MaskProvider>
      )}
    </main>
  );
};
