import React from 'react';
import { StructuredProps } from '../../redesign/types';
import IconBacteria from '../../assets/bacteria.svg';
import IconGut from '../../assets/gut.svg';
import IconDiamondsMerge from '../../assets/diamonds-merge.svg';
import IconThreeCircles from '../../assets/three-circles.svg';

const LargeFont = ({ children }) => (
  <h3 className="ph-font-euclid-medium text-[48px] font-medium leading-[57.6px] tracking-[-1.92px] md:text-[96px] md:leading-[115.2px] md:tracking-[-3.84px] md:capitalize">
    {children}
  </h3>
);

export const StructuredData: StructuredProps = {
  alignment: 'center',
  items: [
    {
      id: 'structured-improve',
      heading:
        'of members improve or resolve symptoms within 12 months or less.',
      description: '',
      illustration: <LargeFont>85%</LargeFont>
    },
    {
      id: 'structured-reduce',
      heading: 'reduction in reliance on chronic prescription medications.',
      description: '',
      illustration: <LargeFont>72%</LargeFont>
    }
  ]
};

export const ProviderTeam = [
  {
    src:
      'https://cdn.sanity.io/images/mur2ep80/production/02a4414f6150702e5ee943b6444387666900decd-1050x1030.png?fm=webp&w=1200&fm=webp',
    name: 'Kelly Candela',
    title: 'Health Coach'
  },
  {
    src:
      'https://cdn.sanity.io/images/mur2ep80/production/5679f7e74f909b98a64a58896def32d03d1a7bf3-2032x2032.png?fm=webp&w=1200&fm=webp',
    name: 'Jackie Damborgian',
    title: 'Health Coach'
  },
  {
    src:
      'https://cdn.sanity.io/images/mur2ep80/production/6b780fb2eb32c6e13f61cbb840ac335b93cb977c-300x300.png?fm=webp&w=1200&fm=webp',
    name: 'Robin Berzin',
    title: 'Doctor'
  },
  {
    src:
      'https://cdn.sanity.io/images/mur2ep80/production/75ded5187e09d7a204299eb7267f80cdf99aac7e-2154x2154.png?fm=webp&w=1200&fm=webp',
    name: 'Lilli Link',
    title: 'Doctor'
  },
  {
    src:
      'https://cdn.sanity.io/images/mur2ep80/production/0e1800bd9bad2e31369844256a3f701c42c9b460-1432x1433.jpg?fm=webp&w=1200&fm=webp',
    name: 'Christina Wakefield',
    title: 'Health Coach'
  },
  {
    src:
      'https://cdn.sanity.io/images/mur2ep80/production/6f21f7609cb5783157b38c206c52619b1909e475-1306x1266.png?fm=webp&w=1200&fm=webp',
    name: 'Erica Zellner',
    title: 'Health Coach'
  },
  {
    src:
      'https://cdn.sanity.io/images/mur2ep80/production/8af631ca2104c4b61c1c4d56e54a50a7b8cc4b28-2362x2357.jpg?fm=webp&w=1200&fm=webp',
    name: 'Kristina Romeo',
    title: 'Health Coach'
  },
  {
    src:
      'https://cdn.sanity.io/images/mur2ep80/production/cf5de42f2418532ec32ea90af6335dbd9d106bc7-1244x1304.png?fm=webp&w=1200&fm=webp',
    name: 'Erica Favela',
    title: 'Health Coach'
  }
];

export const Conditions = [
  {
    title: 'Autoimmune & Inflammation',
    description:
      'Parsley Health providers have deep experience managing triggers to help members find relief with less medication.',
    icon: <IconBacteria />
  },
  {
    title: 'Digestive Issues',
    description:
      '86% of Parsley Health patients with digestive issues find symptom relief within the first year.',
    icon: <IconGut />
  },
  {
    title: 'Hormonal Health',
    description:
      'Many doctors never investigate the symptoms of hormonal imbalance. Parsley Health helps find the balance and relieve symptoms long-term.',
    icon: <IconDiamondsMerge />
  },
  {
    title: 'Mental Health & Wellbeing',
    description:
      'Our medical providers help heal the mind-body connection by helping members navigate stress and anxiety and the physical symptoms they cause (and vice versa).',
    icon: <IconThreeCircles />
  }
];
